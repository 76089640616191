import { useEffect, useState } from "react";
import axios from "axios";
export default function Api(props) {
  const [loading, setLoading] = useState(false);
  const checkValidationErros = (errors) => {
    let newValidationMessages = [];

    for (const key in errors) {
      for (let i = 0; i < errors[key].length; i++) {
        newValidationMessages.push(errors[key][i]);
      }
    }
  };

  useEffect(() => {
    if (props?.options?.url) {
      if (loading) return;
      setLoading(true);
      axios({
        url: props.options.url,
        method: props.options.method,
        params: props.options.params,
        data: props.options.data,
        headers: props.options.headers
          ? props.options.header
          : {
              Accept: "application/json",
            },
      })
        .then(props.options.callback)
        .catch((r) => {
          if (r.response?.status === 422) {
            if (props.options.catch)
              props.options.catch(r.response.data.errors);
            if (props.options.catch422)
              props.options.catch422(r.response.data.errors);
            checkValidationErros(r.response.data.errors);
          } else if (r.response?.status === 401) {
            localStorage.removeItem("auth_token");
          } else if (r.response?.status === 404) {
            if (props.options.catch404)
              props.options.catch404(r.response.data.errors);
          } else {
            //alert(r.message)
          }
        })
        .finally(() => {
          if (props.refresh === "true") {
            window.location.reload(false);
          }
          setLoading(false);
          if (props.options.finally) props.options.finally();
        });
    }
  }, [props.options]); // eslint-disable-line react-hooks/exhaustive-deps
  return <></>;
}
